import { useState, useEffect, ChangeEvent, KeyboardEvent, ClipboardEvent } from 'react';
import { Input as InputText } from '../../ui';
import { validationPattern } from '../../../lib/constants'
import { useLeadContext } from '../../../lib/context';

type props = {
    isChangeNo: boolean
}
export function VerifyOtp({ isChangeNo }: props) {
    const [remainingTime, setRemainingTime] = useState(60);
    const { lead, changeNumber, resendOtp, submit, apiError } = useLeadContext();
    const [prevOtp, setPrevOtp] = useState(0)
    const [getValue, setValue] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: ""
    });

    useEffect(() => {
        if (remainingTime > 0) {
            setTimeout(() => {
                setRemainingTime(t => t - 1);
            }, 1000);
        }
    }, [remainingTime]);

    const autoFillOtp = (e: any) => {
        const value = e.target.value;
        if (value.trim().length > 1) {
            const clipboardEvent = {
                clipboardData: {
                    getData: () => value.trim(),
                },
                preventDefault: () => { },
            } as unknown as ClipboardEvent<HTMLInputElement>;
            handlePaste(clipboardEvent);
        }
    }
    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        const paste = e.clipboardData.getData('text');

        if (paste.length === 4 && new RegExp(validationPattern.Number).test(paste)) {
            setValue({
                otp1: paste[0],
                otp2: paste[1],
                otp3: paste[2],
                otp4: paste[3]
            });
            const doc: any = document.querySelector(`input[name=otp4]`)
            doc?.focus();
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const Number = new RegExp(validationPattern.Number);
        const { name, value, tabIndex } = e.target;
        if (value === '' || (Number.test(value) && value.length <= 2)) {
            setValue((prevValue) => {
                const newValue: any = { ...prevValue, [name]: value };
                let _currentIndex = tabIndex;
                if (value === '') {
                    // _currentIndex = _currentIndex - 1;
                } else if (newValue[name].length === 1) {
                    _currentIndex = _currentIndex + 1;
                }
                else if (value !== '') {
                    if (newValue[name].length === 2) {
                        newValue[name] = value.charAt(1);
                        _currentIndex = _currentIndex + 1;
                    } else {
                        _currentIndex = _currentIndex - 1;
                    }
                }
                if (_currentIndex < 0 || _currentIndex > 4) return newValue;

                const nextSibling: any = document.querySelector(`input[name=otp${_currentIndex}]`);
                if (nextSibling !== null) {
                    nextSibling.focus();
                    // SetTimeout to ensure the focus is applied before updating cursor position
                    setTimeout(() => {
                        nextSibling.setSelectionRange(1, 1);
                    }, 0);
                }
                return newValue;
            });
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const { name, value, tabIndex } = e.currentTarget;
        if (e.key === 'Backspace' && value === '') {
            const prevSibling: any = document.querySelector(`input[name=otp${tabIndex - 1}]`);
            if (prevSibling !== null) {
                prevSibling.focus();
                setTimeout(() => {
                    prevSibling.setSelectionRange(1, 1);
                }, 0);
            }
        }
    };


    const resend = async () => {
        setValue({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: ""
        })
        setPrevOtp(0)
        await resendOtp!();
        setRemainingTime(60);
        document?.getElementsByName('otp1')[0].focus();
    }

    const verifyOTP = async () => {
        if (getValue.otp1 != "" && getValue.otp2 != "" && getValue.otp3 != "" && getValue.otp4 != "") {
            const otp = parseInt(`${getValue.otp1}${getValue.otp2}${getValue.otp3}${getValue.otp4}`);
            if (prevOtp != otp) {
                setPrevOtp(otp)
                await submit!(otp)
            }
        }
    }
    useEffect(() => {
        if ('OTPCredential' in window) {
            const ac = new AbortController();
            navigator.credentials.get({
                otp: { transport: ['sms'] },
                signal: ac.signal
            } as CredentialRequestOptions)
                .then((credential) => {
                    if (credential && 'code' in credential) {
                        const otpCredential = credential as any;
                        const otpCode = otpCredential.code;
                        if (otpCode && otpCode.length === 4) {
                            setValue({
                                otp1: otpCode[0],
                                otp2: otpCode[1],
                                otp3: otpCode[2],
                                otp4: otpCode[3]
                            });

                        }
                    }
                })
                .catch((err) => {
                    if (err.name !== 'AbortError') {
                        console.error('Error retrieving OTP:', err);
                    }
                });

            return () => {
                ac.abort();
            };
        }
    }, []);
    return (
        <>
            <div className="otpBox">
                <h4 className="mb24 font26 lineHeight32 textCenter text222 f600">Verify Phone</h4>
                <div className="mainForm">
                    <p className="font16 textCenter text222 lineHeight26 mb24 mwidth ">We have sent you sms with 4 digit verification code
                        (OTP) on: <span className="fbold">{"+" + lead?.CountryCode + "-" + lead?.MobileNo}</span> </p>
                    {isChangeNo && <p className="font16  mb24" onClick={changeNumber} ><span className="link"> Change Number</span></p>}
                    <form className="mb24">
                        <input type="text" inputMode="numeric" autoFocus autoComplete="one-time-code" pattern={validationPattern.Number} name="otp1" value={getValue.otp1} onChange={(e) => (handleChange(e), autoFillOtp(e))} onKeyDown={handleKeyDown} onPaste={handlePaste} tabIndex={1} />
                        <input type="text" inputMode="numeric" pattern={validationPattern.Number} name="otp2" value={getValue.otp2} onChange={handleChange} onKeyDown={handleKeyDown} onPaste={handlePaste} tabIndex={2} />
                        <input type="text" inputMode="numeric" pattern={validationPattern.Number} name="otp3" value={getValue.otp3} onChange={handleChange} onKeyDown={handleKeyDown} onPaste={handlePaste} tabIndex={3} />
                        <input type="text" inputMode="numeric" pattern={validationPattern.Number} name="otp4" value={getValue.otp4} onChange={handleChange} onKeyDown={handleKeyDown} onPaste={handlePaste} tabIndex={4} />
                    </form>
                    {
                        remainingTime > 0 ? <p className="font16 mb24">Resend OTP in 00:{remainingTime.toString().padStart(2, '0')}</p> : null
                    }
                    {
                        remainingTime == 0 ? <p className="font12 text000 mb24">Did not receive the code? <span className="textpink" onClick={resend} >Resend OTP</span></p> : null
                    }
                    <button className="primaryBtn" id="confirmButton" onClick={verifyOTP}>CONFIRM OTP</button>
                    <div className='otpErrorBx'>
                        {apiError && <div className="otpApiError">{apiError}</div>}
                    </div>
                </div>
            </div>
        </>
    )
}