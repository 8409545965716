import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ILead } from "../../../interfaces";
import { CountryCode, validationPattern } from "../../../lib/constants";
import { useGlobalContext, useLeadContext } from "../../../lib/context"
import { Input as InputText, Select as SelectBox } from '../../ui';
import styles from './ReferAndEarnForm.module.css'
import { API } from "@/lib/helpers/http-client";

const countryData = {
    in: {
        pattern: validationPattern.Number,
        countryCode: "91"
    },
    ae: {
        pattern: validationPattern.Number,
        countryCode: "971"
    }
}
export function ReferAndEarnForm() {
    const { props: { cityMaster, countryCode, countryMaster, isDubaiPage } } = useGlobalContext();
    const { lead, sendOtp, apiError } = useLeadContext();
    const [cities, setCities] = useState(cityMaster);
    const _country = countryData[isDubaiPage ? CountryCode.AED : countryCode];
    const [show, setShow] = useState(false);
    const [getValue, setValue] = useState<ILead>({ ...lead! });
    const [errors, setErrors] = useState({
        Name: "",
        FriendsName: "",
        ProjectCity: "",
        FriendsCity: "",
        MobileNo: "",
        FriendPhoneNo: "",
        Message: "",
        DesignerName: "",
        DesignerEmpCode: "",
        ProjectId: ""
    });
    const handlePhoneNo = async (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let isValiate = true;
        try {
            const result = await API.post('/api/validatephone', {
                countryCode: getValue.CountryCode,
                mobileNo: value
            })
            if (result && result.data == 'false') {
                setErrors({ ...errors, [name]: 'Please enter a valid mobile number' });
                isValiate = false
            } else isValiate = true
        } catch (error) {
            console.error(error)
        }
        return isValiate
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const isValid = isAllowed(name, value)
        if (isValid) {
            setErrors({ ...errors, [name]: "" });
            setValue({ ...getValue, [name]: value });
        }
    };
    const fetchCities = async (countryCode: CountryCode) => {
        try {
            const getCities = await API.get(`/api/master?attributes=cities&countryCode=${countryCode}`);
            if (getCities.status == 200 && getCities.data && Object.keys(getCities.data).length > 0) {
                setCities(getCities.data.cities)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const checkValidation = () => {
        const _errors = errors;
        let isValidate = true;
        if (!getValue.Name || getValue.Name.trim() == "") {
            _errors.Name = "Name is required";
            isValidate = false;
        }
        else
            _errors.Name = "";
        if (!getValue.FriendsName || getValue.FriendsName.trim() == "") {
            _errors.FriendsName = "FriendsName is required";
            isValidate = false;
        }
        else
            _errors.FriendsName = "";
        if (!getValue.ProjectCity || getValue.ProjectCity.trim() == "") {
            _errors.ProjectCity = "City is required";
            isValidate = false;
        }
        else
            _errors.ProjectCity = "";
        if (!getValue.FriendsCity || getValue.FriendsCity.trim() == "") {
            _errors.FriendsCity = "FriendsCity City is required";
            isValidate = false;
        }
        else
            _errors.FriendsCity = "";
        if (!getValue.MobileNo || getValue.MobileNo.trim() == "") {
            _errors.MobileNo = "Mobile No is required";
            isValidate = false;
        }
        else if (getValue.MobileNo === getValue.FriendPhoneNo) {
            _errors.MobileNo = "Your Number Can't Be Same As Your Friends Number"
            isValidate = false;
        }
        else
            _errors.MobileNo = "";
        if (!getValue.FriendPhoneNo || getValue.FriendPhoneNo.trim() == "") {
            _errors.FriendPhoneNo = "Friend Number No is required";
            isValidate = false;
        }
        else
            _errors.FriendPhoneNo = "";
        if (!getValue.Message || getValue.Message.trim() == "") {
            _errors.Message = "Mobile No is required"
        }
        else _errors.Message = ""
        setErrors({ ..._errors });
        return isValidate;
    }

    const submitData = async (e: any) => {
        e && e.preventDefault();
        if (checkValidation()) {
            await sendOtp?.(getValue)
        }
        else return;
    };
    const isAllowed = (name: string, value: string) => {
        const Character = new RegExp(validationPattern.Character);
        const Number = new RegExp(validationPattern.Number);
        const AlphaNumeric = /^[a-zA-Z0-9]+$/;
        switch (name) {
            case 'Name':
                return (value === '' || (Character.test(value)))
            case 'MobileNo':
                return (value === '' || (Number.test(value)))
            case 'FriendsName':
                return (value === '' || (Character.test(value)))
            case 'FriendPhoneNo':
                return (value === '' || (Number.test(value)))
            case 'ProjectId':
                return (value === '' || (AlphaNumeric.test(value)))
            case 'DesignerName':
                return (value === '' || (Character.test(value)))
            case 'DesignerEmpCode':
                return (value === '' || (AlphaNumeric.test(value)))
            default:
                return true;
        }

    }
    useEffect(() => {
        setValue({ ...getValue, ["CountryCode"]: _country.countryCode });
        setValue({ ...getValue, ["FriendsCountryCode"]: _country.countryCode });
        if(isDubaiPage){
            fetchCities(CountryCode.AED);
        }
    }, [countryCode])
    return (
        <div className="ctaLeadForm">
            <div className=" fontSM22 lineheightSM3 modelTitle"><p className="font16 text333 mb10"> Send Invite</p><p className="font14 text000 mb16">Know someone who might be interested in us?</p></div>
            <div className='fbold text333 mb10'>Referral Details</div>
            <div className={styles.optionalDetails} >
                <div className={errors["FriendsCity"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>
                    <div className={styles.dropDown}>
                        <SelectBox name="FriendsCity" value={getValue.FriendsCity || ''} className={styles.formControl} onChange={handleChange} >
                            <option value="" disabled>Choose City</option>
                            {cities && cities.map((city, idx) => <option key={idx} value={city.Name}>{city.Name}</option>)}
                        </SelectBox>
                    </div>
                    <span className='errorText'>{errors["FriendsCity"]}</span>
                </div>
                <div className={errors["FriendsName"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>  {/* add error class */}
                    <InputText id='FriendsName' type="text" pattern={validationPattern.Character} name="FriendsName" value={getValue.FriendsName || ""} required={true} maxLength={50} onChange={handleChange} className={styles.formControl} />
                    <label htmlFor='FriendsName' className={styles.formLable}>Friends Name</label>
                    <span className='errorText'>{errors["FriendsName"]}</span>
                </div>
                <div className={errors["FriendPhoneNo"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>
                    <div className={styles.mobileInput}>
                        <div className={styles.dropDown}>
                            <SelectBox name="FriendsCountryCode" value={getValue.FriendsCountryCode || ""} className={`countryCode ${styles.formControl}`} onChange={handleChange} >
                                <option value="" disabled>-</option>
                                {countryMaster && countryMaster.map((code, idx) => <option key={idx} value={code}>+{code}</option>)}
                            </SelectBox>
                        </div>
                        <InputText id='FriendPhoneNo' type="text" pattern={_country.pattern} name="FriendPhoneNo" value={getValue.FriendPhoneNo} required={true} maxLength={15} onChange={handleChange} onBlur={handlePhoneNo} className={styles.formControl} />
                        <label htmlFor='FriendPhoneNo' className={styles.formLable}>Friend&apos;s Number</label>
                    </div>
                    <span className='errorText'>{errors["FriendPhoneNo"]}</span>
                </div>
            </div>
            <div className='fbold text333 mb10'>Your Details</div>
            <div className={styles.optionalDetails} >
                <div className={errors["ProjectCity"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>
                    <div className={styles.dropDown}>
                        <SelectBox name="ProjectCity" value={getValue.ProjectCity} className={styles.formControl} onChange={handleChange} >
                            <option value="" disabled>Choose City</option>
                            {cities && cities.map((city, idx) => <option key={idx} value={city.Name}>{city.Name}</option>)}
                        </SelectBox>
                    </div>
                    <span className='errorText'>{errors["ProjectCity"]}</span>
                </div>
                <div className={errors["Name"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>  {/* add error class */}
                    <InputText id='Name' type="text" pattern={validationPattern.Character} name="Name" value={getValue.Name} required={true} maxLength={50} onChange={handleChange} className={styles.formControl} />
                    <label htmlFor='Name' className={styles.formLable}>Your Name</label>
                    <span className='errorText'>{errors["Name"]}</span>
                </div>
                <div className={errors["MobileNo"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>
                    <div className={styles.mobileInput}>
                        <div className={styles.dropDown}>
                            <SelectBox name="CountryCode" value={getValue.CountryCode} className={`countryCode ${styles.formControl}`} onChange={handleChange} >
                                <option value="" disabled>-</option>
                                {countryMaster && countryMaster.map((code, idx) => <option key={idx} value={code}>+{code}</option>)}
                            </SelectBox>
                        </div>
                        <InputText id='MobileNo' type="text" pattern={_country.pattern} name="MobileNo" value={getValue.MobileNo} required={true} maxLength={15} onChange={handleChange} onBlur={handlePhoneNo} className={styles.formControl} />
                        <label htmlFor='MobileNo' className={styles.formLable}>Your Mobile No.</label>
                    </div>
                    <span className='errorText'>{errors["MobileNo"]}</span>
                </div>
            </div>
            <div className={errors["ProjectId"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>  {/* add error class */}
                <InputText id='ProjectId' type="text" name="ProjectId" value={getValue.ProjectId} required={true} maxLength={50} onChange={handleChange} className={styles.formControl} />
                <label htmlFor='ProjectId' className={styles.formLable}>Your Project ID</label>
                <span className='errorText'>{errors["ProjectId"]}</span>
            </div>
            <div className={styles.sendInviteCheck}>
                <input type="checkbox" onChange={() => setShow(!show)} checked={show} />
                <label>Are You In Touch with any of Our Designer</label>
            </div>
            {show &&
                <div className={styles.optionalDetails} >
                    <div className={errors["DesignerName"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>  {/* add error class */}
                        <InputText id='DesignerName' type="text" pattern={validationPattern.Character} name="DesignerName" value={getValue.DesignerName} required={true} maxLength={50} onChange={handleChange} className={styles.formControl} />
                        <label htmlFor='DesignerName' className={styles.formLable}>Name</label>
                        <span className='errorText'>{errors["DesignerName"]}</span>
                    </div>

                    <div className={errors["DesignerEmpCode"] && `${styles.referFormItem} error` || `${styles.referFormItem}`}>  {/* add error class */}
                        <InputText id='DesignerEmpCode' type="text" name="DesignerEmpCode" value={getValue.DesignerEmpCode} required={true} maxLength={50} onChange={handleChange} className={styles.formControl} />
                        <label htmlFor='DesignerEmpCode' className={styles.formLable}>Employee Code</label>
                        <span className='errorText'>{errors["DesignerEmpCode"]}</span>
                    </div>
                </div>
            }
            <div className="posR">
                {apiError && <div className="apiError">{apiError}</div>}
                <button className="btn btnPrimery btnBlock" onClick={submitData}>Submit</button></div>

        </div>
    )
}