import React, { useState, useEffect } from 'react';
import Image from "next/image";
import { ILead, ILeadForm } from '../../../interfaces';
import {LeadForm} from './LeadForm';
import {VerifyOtp} from './VerifyOtp';
import {Thanks} from './Thanks';
import { API } from '../../../lib/helpers/http-client';
import { LeadContext, useGlobalContext } from '../../../lib/context';
import { getCookie } from '../../../lib/helpers/Cookie';
import { ReferAndEarnForm } from './ReferAndEarnForm';
import { useKitchenCalcContext } from '@/lib/context/KitchenCalcContext';
import { ExitIntentForm } from './ExitIntentForm';

type props={
    attributes : ILeadForm
}

export function LeadWrapper({attributes}:props){
    const {Title, ButtonText, ModuleType, CssClass, popUpHandler, LeadData, updateVerifyNo, IsGetEstimatePage, MessageForGetEstimate, setEstimatePage, countryCode,SourceType,setThanks}=attributes;
    const {props:{pageURL}} = useGlobalContext();
    const {setStep}=useKitchenCalcContext();
    const Token =()=> getCookie("UTM_TOKEN")
    const [lead, setLead] = useState<ILead>({
        Name:"",
        ProjectCity:"",
        CountryCode:"91",
        MobileNo:"",
        WhatsApp: 1,
        UTM:"",
        WhoAreYou:"",
        Email:""
    });

    const [getFormIndex, setFormIndex] = useState(1);
    const [isLoading, setLoader] = useState(false);
    const [apiError, setApiError] = useState("");
   
    const sendOtp =async(data:ILead|null=null)=>{
        setLoader(true);
        setApiError("");
        if(data!=null)
            setLead(data);
        try {
            let result;
            let sourceType = SourceType ? SourceType : "LEAD" 
            if(sourceType === 'REFERANDEARN'){
                result=await API.get(`/api/referral?countryCode=${data!.CountryCode}&mobileNo=${data!.MobileNo}&pageUrl=${pageURL}`,{
                    headers:{
                        "sourceType":sourceType
                    }
                });
            }else {
                result=await API.post(`/api/corelead?pageUrl=${pageURL}`,{...data,"ModuleType":ModuleType},{
                    headers:{
                        "sourceType":sourceType,
                        "requestType":"generateOtp"
                    }
                });
            }
            if(result && result.status==200){
                setFormIndex(2); 
                if (setEstimatePage) {
                    setEstimatePage(4)
                }
            }
        } catch (error:any) {
            if(error.response && error.response.data)
                setApiError(error.response.data);
            else if(error.message)
                setApiError(error.message);
            else
                setApiError(error);
        }  
        setLoader(false);
    }

    useEffect(()=>{
        if(LeadData && LeadData.OtpSendCount>0){
            setFormIndex(2);
            sendOtp({
                Name:LeadData.Name,
                CountryCode:LeadData.CountryCode.replace("+",""),
                MobileNo:LeadData.MobileNo,
                WhatsApp:0,
                ProjectCity:LeadData.ProjectCity,
                ModuleType:ModuleType,
                UTM:Token()||"",
                WhoAreYou:LeadData.WhoAreYou,
                Email:LeadData.Email
            }) 
        }
    },[LeadData?.OtpSendCount]);

    const resendOtp=()=>{
        sendOtp(lead);
    }

    const changeNumber=()=>{
        setFormIndex(1);
        if (setEstimatePage) {
            setEstimatePage(3)
        }
    }

    const submit = async(otp:number) =>{
        setLoader(true);
        let _body=lead;
        _body.OTP=otp; 
        _body.UTM =Token()||""
        _body.ModuleType=ModuleType; 
        if (MessageForGetEstimate) {
            _body.Message=MessageForGetEstimate
        }

        try {
            let result;
            if (SourceType === "REFERANDEARN") {
                result = await API.post(`/api/referral`,_body);
            } else {
                result = await API.post(`/api/corelead?pageUrl=${pageURL}`,_body,{
                    headers:{
                        "sourceType":SourceType
                    }
                });
            }
            if(result && result.status==200)
            {
              setThanks && setThanks(true)
                if(updateVerifyNo)
                    updateVerifyNo(_body.MobileNo);
                setFormIndex(3);
                if(setStep){
                    setStep(4);
                }
                if (setEstimatePage) {
                    setEstimatePage(5)
                }
            }
        } catch (error:any) {
            if(error.response && error.response.data)
                setApiError(error.response.data);
            else if(error.message)
                setApiError(error.message);
            else
                setApiError(error);
        } 
        setLoader(false);
    }

    const clear = ()=>{    
        setThanks && setThanks(false)    
        setLead({
            Name:"",
            ProjectCity:"",
            CountryCode:"91",
            MobileNo:"",
            WhatsApp: 0,
            UTM:"",
            WhoAreYou:"",
            Email:""
        });
        setFormIndex(1);
        if (setEstimatePage) {
            setEstimatePage(3)
        }
    if(popUpHandler)
            popUpHandler();
    }

    const getForm=()=>{
        switch (getFormIndex) {
            case 2:
                return <VerifyOtp key={2} isChangeNo={!LeadData}/> 
                case 3:
                    return <Thanks key={3} IsGetEstimatePage={IsGetEstimatePage} countryCode={countryCode}/>       
                    default:
                        return <React.Fragment key={1}>
                {SourceType === "REFERANDEARN" ? 
                <ReferAndEarnForm />:
                SourceType === "EXITINTENTPOPUP" ?
                <ExitIntentForm ButtonText={'Get My Discount'} popUpHandler={popUpHandler} /> :
                <LeadForm Title={Title || "Interior Design Services Executed to Perfection"} ButtonText={ButtonText || "Book Consultation"} IsGetEstimatePage={IsGetEstimatePage} setEstimatePage={setEstimatePage}/>  }
                </React.Fragment>
                
        }
    }
    
    return (
        <LeadContext.Provider value={
            {
                lead,
                apiError,
                sendOtp,
                resendOtp,
                changeNumber,
                submit,
                clear,
                sourceType:SourceType
            }
        }>
            <div className={CssClass ? CssClass+" ctaLeadForm":"ctaLeadForm"}>
            {
                [
                    isLoading? <div key={0} className='overlayLoader'><Image
                        src={'/assets/images/loader.gif'}
                        alt='loader'
                        className='img-responsive'
                        width={40}
                        height={40}
                         /></div>:null,
                    getForm()
                ]
            }
            </div>
        </LeadContext.Provider>
    );
}