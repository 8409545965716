import Image from "next/image";
import { useLeadContext } from "../../../lib/context"
import { CountryCode } from '../../../lib/constants';
import { NavLink } from '../../ui';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

type Prop = {
    IsGetEstimatePage?: boolean;
    countryCode?: string;
    IsCareerPage?:boolean
}

const firepixel = process.env.IS_FIRE_PIXEL;
export function Thanks({ IsGetEstimatePage, countryCode, IsCareerPage}: Prop) {
    const {clear,sourceType}=useLeadContext();
    useEffect(()=>{
        if(firepixel == "1")
        {
            const tagManagerArgs = {
                dataLayerName: 'dataLayer',
                dataLayer:{
                    event : 'otp_verified_inco'    
                }          
            };
            TagManager.dataLayer(tagManagerArgs);
        }
    },[]);

    const handleSubmit = () => {
        clear!();
        sourceType === "EXITINTENTPOPUP" && sessionStorage.setItem('isIntentPopup', 'false');
        sourceType === "EXITINTENTPOPUP" && document.body.classList.remove('overflow-hidden');;
    }
    return (
        <div className="thanksBox leadSubmitted" id='thanksBox'>
            {!IsCareerPage ? <h4 className="mb16 font26 lineHeight32 textCenter text222 f600">Thanks For Your Details</h4> :
            <h4 className="mb16 font26 lineHeight32 textCenter text222 f600">Your Application is Submitted</h4>}
            <div className="imgBox textCenter mb16">
                {IsGetEstimatePage ? <Image
                    src="/assets/images/GetestimatethanksYou.svg"
                    height={120}
                    width={123}
                    alt="thanksYou"
                     /> : <Image
                    src="/assets/images/thanksYou.svg"
                    alt="thanksYou"
                    width={276}
                    height={311}
                     />}
            </div>
            <p className="font16 textCenter lineHeight26">Sit Back And Relax <br />
                {IsCareerPage ? "We will contact you shortly.": "Our Design Expert will get in touch with you soon." }
            </p>
            {(IsGetEstimatePage || IsCareerPage) ? countryCode == CountryCode.INR ? <NavLink href='/'><button className="primaryBtn">Back To Home</button></NavLink> : <NavLink href='/ae'><button className="primaryBtn">BACK TO HOME</button></NavLink> : <button className="primaryBtn" onClick={handleSubmit} >OK</button>}
        </div>
    );
}