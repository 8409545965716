import { useEffect, useState } from 'react';
import { Lead } from './Lead';
import { ILeadForm } from '../../../interfaces';
import { ModuleType as Module, ElemenetType } from '../../../lib/constants'

interface ILeadPopUpForm {
    children?: React.ReactNode;
    childrenText?: any;
    ElemenetType: ElemenetType;
    ButtonClass: string;
    ModuleType?: Module;
}

export function LeadPopUpButton({ children, ElemenetType, ButtonClass, childrenText, ModuleType }: ILeadPopUpForm) {
    const [isPopUp, togglePopUp] = useState(false);
    const [isThanks, setThanks] = useState(false)
    const Component = ElemenetType;
    const popUpHandler = () => {
        togglePopUp(!isPopUp);
    }
    useEffect(() => {
        const handleClick = (event: any) => {
            if (event.target.classList.contains('ctaForm') || event.target?.parentElement?.classList?.contains('ctaForm') || event.target?.offsetParent?.classList?.contains('ctaForm') ) {
                togglePopUp(true)
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const popUp = () => {
        return (
            <div className="overlay">
                <div className="regFormBox">
                    {!isThanks && <div className='closeModal' onClick={popUpHandler}>
                        {/* <svg className='imgFluid' viewBox="0 0 14 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 1 1 13M1 1l12 12" stroke="#222" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> */}
                        <div className='leadFormClose'></div>
                    </div>
                    }
                    <Lead ModuleType={ModuleType || Module.INTERIOR} ButtonText="Book Free Consultation" Title="Meet the Designer" popUpHandler={popUpHandler} setThanks={setThanks} />
                </div>
            </div>
        )
    }

    return (
        <>
            {
                isPopUp ? popUp() : null
            }
            <Component className={ButtonClass} onClick={popUpHandler}>
                {
                    children ? children : (childrenText || "Book Free Consultation")
                }
            </Component>
        </>
    )
}