import { ChangeEvent, useEffect, useState } from 'react';
import styles from './ExitIntentForm.module.css';
import { CountryCode, ModuleType as Module, validationPattern } from '@/lib/constants';
import { API } from '@/lib/helpers/http-client';
import { useRouter } from 'next/router';
import { useGlobalContext } from '@/lib/context/GlobalContext';
import { useLeadContext } from '@/lib/context/LeadContext';
import { Input as InputText, Select as SelectBox, NavLink } from '../../ui';
import { ILead } from '@/interfaces/ILead';
import Loader from '../loader/Loader';
import Image from 'next/image';
import { staticLoader } from '@/lib/helpers/imageLoader';
interface ILeadPopUpForm {
    ButtonText: string
    popUpHandler: Function | any
}
const countryData = {
    in: {
        iconPath: "/assets/images/india.png",
        pattern: validationPattern.Number,
        countryCode: "91"
    },
    ae: {
        iconPath: "/assets/images/uae.svg",
        pattern: validationPattern.Number,
        countryCode: "971"
    }
}
export function ExitIntentForm({ ButtonText, popUpHandler }: ILeadPopUpForm) {
    const { props: { cityMaster, countryCode, countryMaster, isDubaiPage } } = useGlobalContext();
    const router = useRouter()
    const CityName = router.query.city?.toLocaleString().slugToText()
    const siteURL = `${process.env.SITE_URL}/${isDubaiPage ? 'dubai' : countryCode}`;
    const [cities, setCities] = useState(cityMaster);
    const [isLoading, setLoader] = useState(false);
    const { lead, sendOtp, apiError } = useLeadContext();
    const _country = countryData[isDubaiPage ? CountryCode.AED : countryCode];
    const [getValue, setValue] = useState<ILead>({ ...lead! });
    const [errors, setErrors] = useState({
        Name: "",
        ProjectCity: "",
        MobileNo: "",
        Email: "",
        WhoAreYou: ""
    });
    const handlePhoneNo = async () => {
        if (getValue.MobileNo === "") return;
        let isValiate = true;
        try {
            const result = await API.post('/api/validatephone', {
                countryCode: getValue.CountryCode,
                mobileNo: getValue.MobileNo
            })
            if (result && result.data == 'false') {
                setErrors({ ...errors, ["MobileNo"]: 'Please enter a valid mobile number' });
                isValiate = false
            } else isValiate = true
        } catch (error) {
            console.error(error)
        }
        return isValiate
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const isValid = isAllowed(name, value)
        if (isValid) {
            if (type == "checkbox")
                setValue({ ...getValue, [name]: (checked ? 1 : 0) });
            else {
                setErrors({ ...errors, [name]: "" });
                setValue({ ...getValue, [name]: value });
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const name = (e.target as HTMLInputElement).name;
        const pastedValue = e.clipboardData.getData('text/plain');
        const isValid = isAllowed(name, pastedValue)
        if (isValid) {
            setValue({ ...getValue, [name]: pastedValue });
            setErrors({ ...errors, [name]: "" });
        }
    }
    const fetchCities = async (countryCode: CountryCode) => {
        try {
            const getCities = await API.get(`/api/master?attributes=cities&countryCode=${countryCode}`);
            if (getCities.status == 200 && getCities.data && Object.keys(getCities.data).length > 0) {
                setCities(getCities.data.cities)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const isAllowed = (name: string, value: string) => {
        const Character = new RegExp(validationPattern.Character);
        const Number = new RegExp(validationPattern.Number);
        switch (name) {
            case 'Name':
                return (value === '' || (Character.test(value)))
            case 'MobileNo':
                return (value === '' || (Number.test(value)))
            default:
                return true;
        }

    }
    const checkValidation = () => {
        const _errors = errors;
        let isValidate = true;
        if (!getValue.Name || getValue.Name.trim() == "") {
            _errors.Name = "Please enter your name";
            isValidate = false;
        }
        else
            _errors.Name = "";
        if (!getValue.ProjectCity || getValue.ProjectCity.trim() == "") {
            _errors.ProjectCity = "Please select your city";
            isValidate = false;
        }
        else
            _errors.ProjectCity = "";
        const emailRegex = new RegExp(validationPattern.Email);
        if (!getValue.Email || getValue.Email.trim() == "") {
            _errors.Email = "Please enter your email address";
            isValidate = false;
        } else if (!emailRegex.test(getValue.Email.trim())) {
            _errors.Email = "Please Enter a Valid Email";
            isValidate = false;
        }
        else
            _errors.Email = "";
        if (!getValue.MobileNo || getValue.MobileNo.trim() == "") {
            _errors.MobileNo = "Please enter your phone number";
            isValidate = false;
        }
        else
            _errors.MobileNo = "";
        setErrors({ ..._errors });
        return isValidate;
    }

    const submitData = async (e: any) => {
        e && e.preventDefault();
        if (checkValidation() && await handlePhoneNo()) {
            setLoader(true)
            await sendOtp?.(getValue)
            setLoader(false)
        }
        else return;
    };
    useEffect(() => {
        if (CityName)
            setValue({ ...getValue, ["ProjectCity"]: CityName === "Gurugram" ? "Gurgaon" : CityName });
        setValue({ ...getValue, ["CountryCode"]: _country.countryCode });
        if (isDubaiPage) {
            fetchCities(CountryCode.AED);
        }
    }, [CityName, countryCode]);
    return (
        <div className={`overlay overLayCenter`} id='exitIntentPopUp'>
            <div className={styles.exitnwpopMinbox}>
                <div className={styles.exitpopMobres}>
                    <div className={styles.exitPoptextmbox}>
                        <span>Enjoy a Special Discount on
                            Your Dream Home!</span>
                        <p className={styles.offerVld}>Get 10% off your first interior design project.
                            Just fill out the form below to claim
                            your discount.</p>
                    </div>

                </div>
                <div className={styles.exitpopLeftimgb}>
                    <figure><Image width={495} height={410} loader={staticLoader} src="/assets/images/newexitoponewimg.webp" alt="newexitoponewimg" /></figure>
                    <div className={styles.exitLftpotextb}>
                        <h4>Enjoy a Special Discount on Your
                            Dream Home!</h4>
                        <p>Get 10% off your first interior design project. <br />
                            Just fill out the form below to claim your discount.</p>

                    </div>
                </div>
                <div className={`${styles.exitnewFormBox} ${styles.popup}`}>
                    <div className={styles.closeBtexitpop} onClick={() => { popUpHandler(), sessionStorage.setItem('isIntentPopup', 'false'), document.body.classList.remove('overflow-hidden'); }}>
                        <div className={styles.leadFormClose}></div>
                    </div>
                    <div className={`ctaLeadForm ${styles.ctaLeadForm}`}>
                        {
                            isLoading && <Loader />
                        }
                        <h3 className="font20 text000 f600 mb24 fontSM16">Connect with the designer today!</h3>
                        <div className={errors["Name"] && "formItem error" || "formItem"}>  {/* add error class */}
                            <InputText type="text" autoComplete='true' name="Name" value={getValue.Name} required={true} maxLength={50} onChange={handleChange} onPaste={handlePaste} className="formControl leadName" />
                            <label htmlFor='Name' className="formLable">Name*</label>
                            <span className='errorText'>{errors["Name"]}</span>
                        </div>
                        <div className={errors["MobileNo"] && "formItem error" || "formItem"}>
                            <div className="mobileInput">
                                <div className='dropDown'>
                                    <SelectBox name="CountryCode" value={getValue.CountryCode} className="countryCode  formControl leadCountryCode" onChange={handleChange}>
                                        <option value="" disabled>-</option>
                                        {countryMaster && countryMaster.map((code, idx) => <option key={idx} value={code}>+{code}</option>)}
                                    </SelectBox>
                                </div>
                                <InputText type="tel" autoComplete='true' name="MobileNo" value={getValue.MobileNo} required={true} maxLength={15} onChange={handleChange} onBlur={handlePhoneNo} onPaste={handlePaste} className="formControl leadMobileNo" />
                                <label htmlFor='MobileNo' className="formLable">Mobile*</label>
                            </div>
                            <span className='errorText'>{errors["MobileNo"]}</span>
                        </div>
                        <div className={errors["Email"] && "formItem error" || "formItem"}>
                            <InputText type="text" name="Email" autoComplete='true' value={getValue.Email} required={true} onChange={handleChange} onPaste={handlePaste} className={`${styles.whiteBg} formControl leadEmail`} />
                            <label htmlFor='Email' className="formLable">Email*</label>
                            <span className='errorText'>{errors["Email"]}</span>
                        </div>
                        <div className={errors["ProjectCity"] && "formItem error" || "formItem"}>
                            <div className="dropDown">
                                <SelectBox name="ProjectCity" value={getValue.ProjectCity} className="formControl leadProjectCity" onChange={handleChange} >
                                    <option value="" disabled>Choose City*</option>
                                    {cities && cities.map((city, idx) => <option key={idx} value={city.Name}>{city.Name}</option>)}
                                </SelectBox>
                            </div>
                            <span className='errorText'>{errors["ProjectCity"]}</span>
                        </div>
                        <div className="posR">
                            {apiError && <div className="apiError">{apiError}</div>}
                            <button className={`btn btnPrimery btnBlock ${styles.reQuestbt}`} onClick={submitData} >{ButtonText}</button>
                        </div>
                        <div className="termsOfusez textCenter">
                            <span>By submitting this form, you agree to the <NavLink className="link" href={siteURL + "/privacy-policy"}>privacy policy</NavLink>&nbsp;and<NavLink className="link" href={siteURL + "/terms-and-conditions"}>&nbsp;terms of use</NavLink></span>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}